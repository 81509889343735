// import React, { useState, useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';
// import Editor from 'draft-js-plugins-editor';
// import { getUrlData } from 'Api';
// import { EditorState, Modifier, SelectionState } from 'draft-js';
// import findWithRegex from 'Utils/findWithRegex';
// import { KeyBindingUtil, RichUtils, getDefaultKeyBinding } from 'draft-js';

// const EditorFile = ({
//   editorRef,
//   data,
//   plugins,
//   onChange,
//   selectedArticleLink,
//   setShowLinkArticleBox,
//   setToggleLinkBox,
//   linkValue,
// }) => {
//   const [editorState, setEditorState] = useState(data);
//   const [urlInside, setUrlInside] = useState([]);

//   const linkRegex = new RegExp(
//     '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
//   );
//   const { hasCommandModifier } = KeyBindingUtil;

//   const handleKeyCommand = (command) => {
//     const newState = RichUtils.handleKeyCommand(editorState, command);
//     if (newState) {
//       setEditorState(newState);
//     }

//     if (editorState.getSelection() && command === 'link') {
//       setToggleLinkBox(true);
//       const contentState = editorState
//         .getCurrentContent()
//         .createEntity('LINK', 'MUTABLE', { link: linkValue });
//       const entityKey = contentState.getLastCreatedEntityKey();
//       const addLinkState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey);

//       setEditorState(addLinkState);
//     } else if (editorState.getSelection() && command === 'bullet') {
//       const bulletState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');

//       setEditorState(bulletState);
//     }
//   };

//   useEffect(() => {
//     setEditorState(data);
//     handleUrlInput();
//     // eslint-disable-next-line
//   }, [data]);

//   useEffect(() => {
//     if (selectedArticleLink) {
//       const contentState = editorState
//         .getCurrentContent()
//         .createEntity('LINK', 'MUTABLE', { link: selectedArticleLink });
//       const entityKey = contentState.getLastCreatedEntityKey();
//       const addLinkState = RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey);

//       setEditorState(addLinkState);
//     }
//     // eslint-disable-next-line
//   }, [selectedArticleLink]);

//   const handleUrlInput = useCallback(
//     () =>
//       urlInside.map((url) =>
//         getUrlData(url)
//           .then((res) => {
//             if (res.data?.brand_name) {
//               const regex = new RegExp(url, 'g');
//               const selectionsToReplace = [];
//               const blockMap = editorState.getCurrentContent().getBlockMap();
//               blockMap.map((contentBlock) =>
//                 findWithRegex(regex, contentBlock, (start, end) => {
//                   const blockKey = contentBlock.getKey();
//                   const blockSelection = SelectionState.createEmpty(blockKey).merge({
//                     anchorOffset: start,
//                     focusOffset: end,
//                   });
//                   selectionsToReplace.push(blockSelection);
//                 }),
//               );

//               let contentState = editorState.getCurrentContent();
//               selectionsToReplace.map(
//                 (selectionState) =>
//                   (contentState = Modifier.replaceText(
//                     contentState,
//                     selectionState,
//                     res.data?.brand_name,
//                   )),
//               );
//               const editorStateWithReplacedURL = EditorState.push(editorState, contentState);
//               let contentStateWithReplacedURL = editorStateWithReplacedURL.getCurrentContent();
//               const blockMapWithReplacedURL = contentStateWithReplacedURL.getBlockMap();

//               let startKey, endKey, startOffset, endOffset;
//               blockMapWithReplacedURL.forEach((block) => {
//                 const blockText = block.getText();
//                 const startIndex = blockText.indexOf(res.data?.brand_name);
//                 if (startIndex !== -1) {
//                   startKey = block.getKey();
//                   endKey = block.getKey();
//                   startOffset = startIndex;
//                   endOffset = startIndex + res.data?.brand_name?.length;
//                 }
//               });

//               const selection = editorStateWithReplacedURL.getSelection().merge({
//                 anchorKey: startKey,
//                 anchorOffset: startOffset,
//                 focusKey: endKey,
//                 focusOffset: endOffset,
//               });

//               const newEditorState = EditorState.forceSelection(
//                 editorStateWithReplacedURL,
//                 selection,
//               );
//               const selectionTerm = newEditorState.getSelection();
//               if (!selectionTerm.isCollapsed()) {
//                 const contentState = newEditorState.getCurrentContent();
//                 const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
//                   url,
//                 });
//                 const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

//                 const newContentState = Modifier.applyEntity(
//                   contentStateWithEntity,
//                   selection,
//                   entityKey,
//                 );
//                 const newEditorStateWithLink = EditorState.push(
//                   editorState,
//                   newContentState,
//                   'apply-entity',
//                 );

//                 setEditorState(newEditorStateWithLink);
//               }
//             }
//           })
//           .catch((err) => console.log(err)),
//       ),
//     [editorState, urlInside],
//   );

//   const handleCustomShortcut = (e) => {
//     if (e.keyCode === 75 && hasCommandModifier(e)) {
//       //Cmd+k
//       return 'link';
//     } else if (e.keyCode === 56 && e.shiftKey && hasCommandModifier(e)) {
//       return 'bullet';
//     }
//     return getDefaultKeyBinding(e);
//   };

//   return (
//     <Editor
//       keyBindingFn={handleCustomShortcut}
//       handleKeyCommand={handleKeyCommand}
//       ref={editorRef}
//       editorState={editorState}
//       plugins={plugins}
//       className="inEditor"
//       key="inEditor"
//       title="Content"
//       placeholder="Content"
//       onChange={(value) => {
//         setEditorState(value);
//         let textVal = editorState.getCurrentContent().getPlainText('\u0001');

//         textVal
//           .split(' ')
//           .map((blockItem) => {
//             if (linkRegex.test(blockItem) && !urlInside.includes(blockItem))
//               setUrlInside((prevVal) => [...prevVal, blockItem]);
//           })
//           .join(' ');

//         setShowLinkArticleBox(false);
//       }}
//     />
//   );
// };

// EditorFile.propTypes = {
//   editorRef: PropTypes.object,
//   data: PropTypes.object,
//   plugins: PropTypes.array,
//   onChange: PropTypes.func,
// };

// export default EditorFile;

import { Editor } from '@tinymce/tinymce-react';
import { fetchInlineImageUrl, fetchInlineVideoUrl } from 'Api';
import resizeImage from 'Utils/resizeFile';
import React, { useEffect } from 'react';

import { useState } from 'react';

export const tinyMceApiKey = 'yd12kjj74kaco5j5c4iqyx1z5dax03g72x4ygvpk38piqyh2';



const EditorFile = ({
  setShowLinkArticleBox,
  showLinkArticleBox,
  editorRef,
  handleEditorChange,
  data,
  selectedArticleLink,
  // dispatch,
  setOpenVersion,
  openVersion,
}) => {
  // const [urlInside, setUrlInside] = useState([]);
  // const [allBrandNames, setAllBrandNames] = useState([]);

  const [editorInitialValu, setEditorInitialValu] = useState('<p></p>');

  useEffect(() => {
    if (!editorInitialValu?.replace(/(<([^>]+)>)/gi, '') && data?.replace(/(<([^>]+)>)/gi, '')) {
      setEditorInitialValu(data);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (selectedArticleLink) {
      const editor = editorRef.current;

      const selectedText = editor.selection.getContent({ format: 'text' });

      if (selectedText) {
        const linkUrl = selectedArticleLink;

        if (linkUrl) {
          const html = `<a href="${linkUrl}">${selectedText}</a>`;

          editor.execCommand('mceInsertContent', false, html);
        }
      }
    }
    // eslint-disable-next-line
  }, [selectedArticleLink, editorRef]);

  // useEffect(() => {
  //   if (allBrandNames.length > 0) {
  //     // eslint-disable-next-line
  //     allBrandNames.map((brand) => {
  //       const regex = new RegExp(brand.brandName, 'gi');
  //       const replacedContent = data.replace(
  //         regex,
  //         `<a href="${brand.brandUrl}">${brand.brandName}</a>`,
  //       );

  //       dispatch({
  //         type: 'SET_EDITOR_STATE_HTML_CONTENT',
  //         value: replacedContent,
  //       });

  //       setAllBrandNames(
  //         allBrandNames.filter((existingBrand) => existingBrand.brandName !== brand.brandName),
  //       );
  //     });
  //   }

  //   // eslint-disable-next-line
  // }, [allBrandNames]);

  // useEffect(() => {
  //   handleUrlInput();
  //   // eslint-disable-next-line
  // }, [urlInside]);

  // const linkRegex = new RegExp(
  //   '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
  // );

  // const handleUrlInput = () =>
  //   urlInside.map((url) =>
  //     getUrlData(url)
  //       .then((res) => {
  //         if (res.data?.brand_name) {
  //           dispatch({
  //             type: 'SET_EDITOR_STATE_HTML_CONTENT',
  //             value: data.replace(url, res.data?.brand_name),
  //           });

  //           setAllBrandNames((prevValue) => [
  //             ...prevValue,
  //             { brandUrl: url, brandName: res.data.brand_name },
  //           ]);
  //         }
  //       })
  //       .catch((err) => console.log(err)),
  //   );

  return (
    <>
      <Editor
        initialValue={editorInitialValu}
        // value={data}
        id="editorArea"
        onEditorChange={(content) => {
          handleEditorChange(content);         
        }}
        apiKey={tinyMceApiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          contextmenu: false,
          images_upload_handler: async function (blobInfo) {
            const formData = new FormData();
            formData.append('content_image', blobInfo.blob(), blobInfo.filename());
            const response = await fetchInlineImageUrl(formData);
            return response.data.content_image_url;
          },               
          file_picker_callback: async (cb, value, meta) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'video/*');        
            input.onchange = async function () {
                const file = this.files[0];        
                if (file.type.includes('video')) {
                    const formData = new FormData();                               
                    formData.append('video_file', file);                          
                    try {                        
                        const response = await fetchInlineVideoUrl(formData);
                        if (file.type.includes('video')) {
                            cb(response.data.s3_video_url, { title: file.name });
                        }
                    } catch (error) {
                        console.error('Error uploading media:', error);
                    }
                } 
            };        
            input.click();
          },                
          setup: (editor) => {
            editor.on('input', (e) => {
              e.preventDefault();
              // handleEditorChange(e.target?.innerHTML?.replace(/"/g, "'"));
              handleEditorChange(e.target?.innerHTML);
            });
            editor.on('ScrollIntoView', (e) => {
              e.preventDefault();
            });

            editor.on('preProcess', function (e) {
              var images = editor.contentDocument.getElementsByTagName('img');
              for (var i = 0; i < images.length; i++) {
                var img = images[i];
                resizeImage(img, 1024, 576); // Adjust the maxWidth and maxHeight as needed
              }
            });

            editor.on('keydown', function (e) {
              if (e.keyCode === 56 && e.ctrlKey && e.shiftKey) {
                e.preventDefault();
                editor.execCommand('InsertUnorderedList');
              }
            });
            /*
             * Add a custom icon to TinyMCE
             */

            editor.ui.registry.addButton('linkArticle', {
              icon: 'document-properties',
              onAction: () => {
                setShowLinkArticleBox(!showLinkArticleBox);
              },
            });

            editor.ui.registry.addButton('version', {
              icon: 'insert-time',
              onAction: () => {
                setOpenVersion(!openVersion);
              },
            });
          },
          file_picker_types: 'media',
          media_live_embeds: true,
          toolbar_mode: 'wrap',
          resize: true,
          height: 450,
          menubar: false,
          branding: false,
          plugins: [
            'advlist',
            // 'autolink',
            'lists',
            'link',
            'image',
            'anchor',
            'media',
            'wordcount',
          ],
          toolbar:
            'image media | blocks | lists bold italic forecolor | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat version wordcount link linkArticle',
          content_css:
            'https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap',
          content_style: `img { max-width: 100%; height: auto; } body { font-family: 'PT Serif', serif; font-size: 20px; }`,
        }}
      />
    </>
  );
};

export default EditorFile;


